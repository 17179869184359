import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { getHeight } from '../lib/utils'

export default function (): void {
  gsap.registerPlugin(ScrollTrigger)

  let tastingRoomsContainer: Element | null =
    document.getElementById('tasting-rooms')

  if (!tastingRoomsContainer) {
    return
  }

  let cards: Element[] = gsap.utils.toArray('.tasting-room')
  let counters: Element[] = gsap.utils.toArray('.tasting-rooms-counter li')

  gsap.set(tastingRoomsContainer, { height: '100svh' })

  cards.forEach((card) => {
    gsap.set(card, { position: 'absolute' })
  })

  counters.forEach((counter) => {
    gsap.set(counter, { opacity: 0.3 })
  })

  let tl = gsap.timeline({
    default: {
      ease: 'none',
    },
    scrollTrigger: {
      trigger: '#tasting-rooms',
      start: 'top top',
      end: () =>
        `bottom+=${getHeight(tastingRoomsContainer, 2) * cards.length}`,
      scrub: true,
      pin: true,
      // markers: true,
      invalidateOnRefresh: true,
    },
  })

  cards.forEach((card, i) => {
    const img = card.querySelector('img')
    const p = card.querySelectorAll('p')
    const h2 = card.querySelector('h2')

    gsap.set(card, { pointerEvents: 'none' })

    tl.set(counters[i], { opacity: 1 })
    tl.set(card, { pointerEvents: 'all' })

    // Intro - Skip the first card
    if (i !== 0) {
      gsap.set([p, h2], { opacity: 0, scale: 0.9 })

      tl.addLabel('intro')
        .fromTo(img, { opacity: 0 }, { opacity: 1 }, 'intro')
        .fromTo(
          p,
          { opacity: 0, scale: 0.9 },
          { opacity: 1, scale: 1, stagger: 0.1 },
          'intro',
        )
        .fromTo(
          h2,
          { opacity: 0, scale: 0.9 },
          { opacity: 1, scale: 1 },
          'intro',
        )
    } else {
      // delay first outro animation
      tl.to(card, { delay: 0.5 })
    }

    // Outro - Skip the last card
    if (i < cards.length - 1) {
      tl.to([h2, p], { opacity: 0, scale: 1.1, stagger: 0.1 })
    }

    tl.set(card, { pointerEvents: 'none' })
    tl.set(counters[i], { opacity: 0.3 })
  })
}
