// Two column sections with an image on the right and text on the left

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { getHeight } from '../lib/utils'

export default function (): void {
  gsap.registerPlugin(ScrollTrigger)

  let sections: Element[] = gsap.utils.toArray('[data-feature-section]')

  if (!sections.length) {
    return
  }

  let mm = gsap.matchMedia()

  mm.add('(min-width: 768px)', () => {
    sections.forEach((section) => {
      let cards: Element[] = gsap.utils.toArray(
        section.querySelectorAll('[data-feature-card]'),
      )
      let counters: Element[] = gsap.utils.toArray('.pagination div')

      gsap.set(sections, { height: '100svh' })

      // stack cards
      cards.forEach((card) => {
        gsap.set(card, {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        })
      })

      counters.forEach((counter) => {
        gsap.set(counter, { opacity: 0.3 })
      })

      let tl = gsap.timeline({
        default: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: section,
          start: 'top top',
          end: () => `bottom+=${getHeight(section) * (cards.length + 1)}`,
          scrub: true,
          pin: true,
          // markers: true,
          invalidateOnRefresh: true,
        },
      })

      cards.forEach((card, i) => {
        const imgs = card.querySelector('.group')
        const copy = card.querySelector('[data-feature-copy]')
        const h2 = card.querySelector('h2')

        gsap.set(card, { pointerEvents: 'none' })
        gsap.set(imgs, { height: '100svh' })

        tl.set(counters[i], { opacity: 1 })
        tl.set(card, { pointerEvents: 'all' })

        // Intro - Skip the first card
        if (i !== 0) {
          gsap.set([copy, h2], { opacity: 0, yPercent: 20 })
          gsap.set(imgs, { opacity: 0 })

          tl.addLabel('intro')
            .fromTo(imgs, { opacity: 0 }, { opacity: 1 }, 'intro')
            .fromTo(
              copy,
              { opacity: 0, yPercent: 20 },
              { opacity: 1, yPercent: 0, stagger: 0.1 },
              'intro',
            )
            .fromTo(
              h2,
              { opacity: 0, yPercent: 20 },
              { opacity: 1, yPercent: 0 },
              'intro',
            )
        }

        // scroll content if needed
        if (copy && copy.clientHeight > card.clientHeight) {
          tl.to(
            copy,
            {
              y: () => window.innerHeight - copy.clientHeight,
            },
            'intro',
          )
        }

        // Outro - Skip the last card
        if (i < cards.length - 1) {
          // delay first slide outro animation
          tl.to(card, { delay: 0.25 })
          tl.to([h2, copy], { opacity: 0, yPercent: -10 })
          tl.set(card, { pointerEvents: 'none' })
          tl.set(counters[i], { opacity: 0.3 })
        }

        // delay last slide outro animation
        if (i === cards.length - 1) {
          tl.to(card, { delay: 0.25 })
        }
      })
    })
  })
}
