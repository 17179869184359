import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/all'
import { getHeight } from '../lib/utils'

export default function collectionScroller(): void {
  gsap.registerPlugin(ScrollTrigger, SplitText)

  let container: Element | null = document.getElementById('collection-scroller')

  if (!container) {
    return
  }

  let cards: HTMLElement[] = gsap.utils.toArray(
    container.querySelectorAll('article'),
  )
  let counters: HTMLElement[] = gsap.utils.toArray('.counter li')

  const mm = gsap.matchMedia()

  mm.add('(min-width: 768px)', () => {
    // invert z-index and stack
    cards.forEach((card, idx) => {
      const invertedIdx = cards.length - 1 - idx
      gsap.set(card, { position: 'absolute', zIndex: invertedIdx })
    })

    // set height
    gsap.set(container, { height: '100svh' })

    // setup counters
    counters.forEach((counter) => {
      gsap.set(counter, { opacity: 0.3, display: 'block' })
    })

    let tl = gsap.timeline({
      default: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: container,
        start: 'top top',
        end: () => `bottom+=${getHeight(container, 3) * cards.length}`,
        scrub: true,
        pin: true,
        // markers: true,
        invalidateOnRefresh: true,
        snap: 1 / (cards.length - 1),
      },
    })

    cards.forEach((card, i) => {
      const content = card.querySelectorAll('.content > *')
      const img = card.querySelector('figure')
      const header = card.querySelector('.header')
      const logo = card.querySelector('.logo')

      const headerSplit = new SplitText(header, {
        type: 'chars,words,lines',
        linesClass: 'overflow-hidden',
      })

      gsap.set(card, { pointerEvents: 'none' })

      tl.set(counters[i], { opacity: 1 })
      tl.set(card, { pointerEvents: 'all' })

      // Intro - Skip the first card
      if (i !== 0) {
        gsap.set([content, logo], { opacity: 0, y: -20 })
        gsap.set(headerSplit.chars, {
          autoAlpha: 0,
          yPercent: 60,
          overwrite: true,
        })

        tl.addLabel('intro')
          .fromTo(img, { opacity: 0 }, { opacity: 1 }, 'intro')
          .fromTo(logo, { opacity: 0, y: -20 }, { opacity: 1, y: 0 }, 'intro')
          .add(() => {
            if (tl.scrollTrigger?.direction === 1) {
              gsap.fromTo(
                headerSplit.chars,
                {
                  autoAlpha: 0,
                  yPercent: 60,
                  overwrite: true,
                },
                {
                  autoAlpha: 1,
                  yPercent: 0,
                  duration: 1,
                  ease: 'power2',
                  stagger: {
                    each: 0.1,
                    from: 'random',
                  },
                },
              )
            } else {
              gsap.to(headerSplit.chars, {
                autoAlpha: 0,
                yPercent: 60,
                duration: 0.5,
                ease: 'power2',
                overwrite: true,
              })
            }
          }, 'intro')
          .fromTo(
            content,
            { opacity: 0, y: -20 },
            { opacity: 1, y: 0, stagger: 0.1 },
            'intro',
          )
      }

      // Outro - Skip the last card
      if (i < cards.length - 1) {
        tl.to([content, header, logo], { opacity: 0, y: 20, stagger: 0.1 })
        tl.to(img, { opacity: 0 })
      }

      tl.set(card, { pointerEvents: 'none' })
      tl.set(counters[i], { opacity: 0.3 })
    })
  })
}
