import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/all'
import { getHeight } from '../lib/utils'

export default function (): void {
  gsap.registerPlugin(ScrollTrigger, SplitText)

  let storyContainer: Element | null =
    document.getElementById('product-scroller')

  if (!storyContainer) {
    return
  }

  let cards: HTMLElement[] = gsap.utils.toArray(
    storyContainer.querySelectorAll('article'),
  )
  let counters: HTMLElement[] = gsap.utils.toArray('.story-counter li')

  const mm = gsap.matchMedia()

  mm.add('(min-width: 768px) and (min-height: 700px)', () => {
    // invert z-index and stack
    cards.forEach((card, idx) => {
      const invertedIdx = cards.length - 1 - idx
      gsap.set(card, {
        position: 'absolute',
        zIndex: invertedIdx,
        height: '100vh',
      })
    })

    // set height
    gsap.set(storyContainer, { height: '100svh' })

    let tl = gsap.timeline({
      default: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: storyContainer,
        start: 'top top',
        end: () => `bottom+=${getHeight(storyContainer, 3) * cards.length}`,
        scrub: true,
        pin: true,
        // markers: true,
        invalidateOnRefresh: true,
        snap: 1 / (cards.length - 1),
      },
    })

    counters.forEach((counter) => {
      gsap.set(counter, { opacity: 0.3, display: 'block' })
    })

    cards.forEach((card, i) => {
      const content = card.querySelectorAll('.content > *')
      const img = card.querySelector('figure')
      const product = card.querySelector('.product')

      gsap.set(card, { pointerEvents: 'none' })

      tl.set(counters[i], { opacity: 1 })
      tl.set(card, { pointerEvents: 'all' })

      // Intro - Skip the first card
      if (i !== 0) {
        gsap.set([product, content], { opacity: 0, y: -20 })

        tl.addLabel('intro')
          .fromTo(img, { opacity: 0 }, { opacity: 1 }, 'intro')
          .fromTo(
            product,
            { opacity: 0, y: -20 },
            { opacity: 1, y: 0 },
            'intro',
          )
          .fromTo(
            content,
            { opacity: 0 },
            { opacity: 1, stagger: 0.1 },
            'intro',
          )
      }

      // Outro - Skip the last card
      if (i < cards.length - 1) {
        tl.to([product, content], { opacity: 0, y: 20, stagger: 0.1 })
        tl.to(img, { opacity: 0 })
        tl.set(card, { pointerEvents: 'none' })
        tl.set(counters[i], { opacity: 0.3 })
      }
    })
  })
}
