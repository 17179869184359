import 'what-input'
import Cookies from 'js-cookie'
import AsyncAlpine from 'async-alpine'
import Alpine from 'alpinejs'
import interset from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import tastingRooms from './components/tastingRooms'
import featureSections from './components/featureSections'
import vision from './components/vision'
import jcbStory from './components/jcbStory'
import collectionScroller from './components/collectionScroller'
import productScroller from './components/productScroller'
import './lib/liteYoutube.ts'
import './lib/liteVimeo.ts'
import animations from './animations'
import logoRow from './components/logoRow'

window.Alpine = Alpine
window.Cookies = Cookies

Alpine.plugin([interset, focus, collapse])

Alpine.directive('logo-row', logoRow)

AsyncAlpine.init(Alpine)
AsyncAlpine.data('featureSlider', () => import('./components/featureSlider'))
AsyncAlpine.data('productSlider', () => import('./components/productSlider'))
AsyncAlpine.data(
  'productImageSlider',
  () => import('./components/productImageSlider'),
)
AsyncAlpine.data('rowSlider', () => import('./components/rowSlider'))
AsyncAlpine.data('gallerySlider', () => import('./components/gallerySlider'))
AsyncAlpine.data('copySlider', () => import('./components/copySlider'))
AsyncAlpine.start()

Alpine.start()

// Animations
featureSections()
tastingRooms()
vision()
jcbStory()
collectionScroller()
productScroller()
animations()

const newsletterForm = document.querySelector(
  '[data-form-code="website-newsletter-signup"]',
)

function newsletterMutated(records) {
  let newsletterFormEl = newsletterForm.querySelector('form')
  if (newsletterFormEl) {
    let subscribeControl = newsletterFormEl.querySelector('.c7-checkbox')

    // subscribeCheckbox.checked = true
    subscribeControl.click()
  }
}

if (newsletterForm) {
  const observer = new MutationObserver(newsletterMutated)
  observer.observe(newsletterForm, { childList: true })
}
