export function checkImagesLoaded(
  images: HTMLCollectionOf<HTMLImageElement>,
  callback?: () => void,
) {
  let loaded = 0

  function imageLoaded() {
    loaded++

    if (loaded === images.length) {
      // All images have loaded
      console.log('All images have loaded')
      callback?.()
    }
  }

  ;[...images].forEach((img) => {
    if (img.complete) {
      // Image has already loaded
      loaded++
    } else {
      // Image hasn't loaded yet, attach the onload event
      img.addEventListener('load', imageLoaded)
    }
  })
}

export function getHeight(el: Element | null, multiplier = 1): number {
  if (!el) return 0
  return el.getBoundingClientRect().height * multiplier
}
