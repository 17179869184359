import type {
  DirectiveData,
  DirectiveUtilities,
  ElementWithXAttributes,
} from 'alpinejs'

// Function to check if all images in the array have loaded
function areAllImagesLoaded(images: HTMLImageElement[]) {
  for (const image of images) {
    if (!image.complete || !image.naturalHeight) {
      return false
    }
  }
  return true
}

// Function to wait for all images in the array to load
function waitForImagesToLoad(
  images: HTMLImageElement[],
  callback?: () => void,
) {
  if (!areAllImagesLoaded(images)) {
    // If not all images are loaded, wait for a short duration and check again
    setTimeout(() => waitForImagesToLoad(images), 100)
  } else {
    // All images are loaded, do something
    callback?.()
  }
}

export default function LogoRow(
  el: ElementWithXAttributes,
  directive: DirectiveData,
  utilities: DirectiveUtilities,
): void {
  function adjustImageWidth(image: HTMLImageElement) {
    const widthBase = 200
    const scaleFactor = 0.525
    const imageRatio = image.naturalWidth / image.naturalHeight

    image.width = Math.pow(imageRatio, scaleFactor) * widthBase
  }

  document.addEventListener('DOMContentLoaded', () => {
    const imageElements = el.querySelectorAll('img')
    const imageArray = Array.from(imageElements)

    waitForImagesToLoad(imageArray, () => {
      imageElements.forEach(adjustImageWidth)
    })
  })
}
