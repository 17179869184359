import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/all'
import { checkImagesLoaded } from './lib/utils'

// .circle-fade
// .fade-up
// .fade-up-children
// .parallax > [data-speed] + .parallel-bg
// .tilt-effect > [data-tilt]
// .animate-header > h1, .animate-header > h2

export default function (): void {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(SplitText)

  headerText()
  parallax()
  Tilt3D()

  // TODO move to components
  // being used in feature sliders
  gsap.to('.circle-fade', {
    '--mask-start': '50%',
    duration: 2,
    ease: 'power1.easeIn',
    yoyoEase: true,
    yoyo: true,
    repeat: -1,
  })

  gsap.utils.toArray<HTMLElement>('.fade-up').forEach((el) => {
    const images = el.getElementsByTagName('img')
    checkImagesLoaded(images, () => {
      ScrollTrigger.refresh()
    })

    gsap.set(el, { opacity: 0, y: 30 })
    gsap.to(el, {
      duration: 2,
      ease: 'power2.out',
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: el,
        start: 'top 80%',
        toggleActions: 'play reverse play reverse',
      },
    })
  })

  gsap.utils.toArray<HTMLElement>('.fade-up-children').forEach((el) => {
    const images = el.getElementsByTagName('img')
    checkImagesLoaded(images, () => {
      ScrollTrigger.refresh()
    })

    gsap.set(el.children, { opacity: 0, y: 20 })
    gsap.to(el.children, {
      duration: 2,
      ease: 'power2.out',
      opacity: 1,
      y: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: el,
        start: 'top 80%',
        invalidateOnRefresh: true,
        toggleActions: 'play reverse play reverse',
      },
    })
  })
}

export function headerText() {
  const textEls = gsap.utils.toArray<HTMLElement>([
    '.animate-header h1',
    '.animate-header h2',
  ])
  if (!textEls.length) {
    return
  }

  const splitText = textEls.map(
    (text) =>
      new SplitText(text, {
        type: 'chars,words,lines',
        linesClass: 'overflow-hidden pb-1',
      }),
  )

  textEls.forEach((text, index) => {
    gsap.fromTo(
      splitText[index].chars,
      {
        autoAlpha: 0,
        yPercent: 60,
        overwrite: true,
      },
      {
        autoAlpha: 1,
        yPercent: 0,
        duration: 1,
        ease: 'power2',
        stagger: {
          each: 0.01,
          from: 'random',
        },
        scrollTrigger: {
          trigger: text.parentElement,
          // markers: true,
          start: 'top 80%',
          end: 'bottom top',
          toggleActions: 'play reverse play reverse',
        },
      },
    )
  })
}

export function parallax() {
  const mm = gsap.matchMedia()

  mm.add('(min-width: 768px)', () => {
    const sections = gsap.utils.toArray<HTMLElement>('.parallax')
    sections.forEach((section) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      })

      const els = gsap.utils.toArray<HTMLElement>(
        section.querySelectorAll('[data-speed]'),
      )
      tl.to(
        els,
        {
          y: (_, target) => {
            return target.offsetHeight * -target.dataset.speed
          },
          ease: 'none',
        },
        0,
      )
    })

    gsap.to('.parallax-bg', {
      scrollTrigger: {
        scrub: true,
      },
      y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
      ease: 'none',
    })
  })
}

export function Tilt3D() {
  const mm = gsap.matchMedia()

  mm.add('(pointer:fine)', () => {
    // 3D hover effect
    const els = gsap.utils.toArray<HTMLElement>('.tilt-effect')

    function mouseMoveAnim(e: Event, els: HTMLElement[]) {
      const ev = e as MouseEvent
      const xPos = ev.clientX / window.innerWidth - 0.5
      const yPos = ev.clientY / window.innerHeight - 0.5

      gsap.to(els, {
        duration: 0.1,
        rotationY: (_, target) => {
          return target.dataset.tilt ? target.dataset.tilt * xPos : 40 * xPos
        },
        rotationX: (_, target) => {
          return target.dataset.tilt ? -target.dataset.tilt * yPos : -40 * yPos
        },
        x: (_, target) => {
          return target.dataset.tilt
            ? target.dataset.tilt * -8 * xPos
            : -100 * xPos
        },
        y: (_, target) => {
          return target.dataset.tilt
            ? target.dataset.tilt * -3 * yPos * 3
            : -100 * yPos
        },
        ease: 'power2.inOut',
        transformPerspective: 900,
        transformOrigin: 'center',
      })
    }

    if (els.length > 0) {
      els.forEach((el) => {
        // get all the elements with data-tilt
        const els = gsap.utils.toArray<HTMLElement>(
          el.querySelectorAll('[data-tilt]'),
        )
        // create a currying function that can pass els
        // to the mouseMoveAnim function
        const curry = function (els: HTMLElement[]) {
          return (e: Event) => mouseMoveAnim(e, els)
        }
        // Create a function so we can use removeEventListener later
        const moveHandler = (ev: Event) => curry(els)(ev)

        el.onmouseenter = function () {
          el.addEventListener('mousemove', moveHandler)
        }

        el.onmouseleave = function () {
          el.removeEventListener('mousemove', moveHandler)

          gsap.to(els, {
            delay: 0.1,
            duration: 0.5,
            rotationY: 0,
            rotationX: 0,
            x: 0,
            y: 0,
            ease: 'power1.inOut',
          })
        }
      })
    }
  })
}
