import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/all'

export default function (): void {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(SplitText)

  const el = document.getElementById('vision')

  if (!el) {
    return
  }

  const images: HTMLElement[] = gsap.utils.toArray('.vision-img')
  const imagesParent = images[0]?.parentElement
  let mm = gsap.matchMedia()

  const splitText = new SplitText('.vision-copy', {
    type: 'words',
  })

  gsap.from(splitText.words, {
    scale: gsap.utils.random(0.2, 2),
    opacity: 0,
    stagger: 0.02,
    scrollTrigger: {
      trigger: '.vision-copy',
      start: 'top 80%',
      end: 'bottom top',
      // markers: true,
      toggleActions: 'play reverse play reverse',
      invalidateOnRefresh: true,
    },
  })

  mm.add('(min-width: 768px', () => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true,
        // markers: true,
      },
    })

    // prettier-ignore
    tl
      .addLabel('title')
      .fromTo('.vision-line-1', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.75, ease: 'power.in' })
      .to('.vision-line-1', { y: '-20%', duration: 0.2 })
      .fromTo('.vision-line-2', { y: '100%' }, { y: '0', duration: 0.8 }, 'title')
      .fromTo(images[0], { y: '20%' }, { y: '-20%', duration: 1 }, 'title')
      .fromTo(images[1], { y: '-50%' }, { y: '50%', duration: 1 }, 'title')
      .fromTo(images[2], { x: '0%' }, { x: '-20%', duration: 1 }, 'title')
  })

  mm.add('(max-width: 767px', () => {
    gsap.set(images, {
      x: (num, el) => {
        const elementRect = el.getBoundingClientRect()
        const elementX = elementRect.left + elementRect.width / 2
        return window.innerWidth / 2 - elementX
      },
    })

    let tl = gsap.timeline({
      defaults: {
        duration: 2,
        ease: 'expo.inOut',
      },
      scrollTrigger: {
        trigger: imagesParent,
        start: 'center 80%',
        end: 'center top',
        // markers: true,
        toggleActions: 'play reverse play reverse',
        invalidateOnRefresh: true,
      },
    })

    tl.fromTo(images[0], { yPercent: 20 }, { x: 0, yPercent: 0 }, 'images')
      .fromTo(images[1], { yPercent: 20 }, { x: 0, yPercent: 0 }, 'images')
      .fromTo(images[2], { yPercent: -100 }, { x: 0, yPercent: 0 }, 'images')
  })
}
